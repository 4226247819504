import React, { useState, useEffect } from "react";
import "../css/layout.css";
import "../css/background-image.css";
import Helmet from "react-helmet";
import axios from "axios";
import Spinner from "@atlaskit/spinner";
import { ZoomMtg } from "@zoomus/websdk";
import Textfield from "@atlaskit/textfield";
import Button from "@atlaskit/button";
import backLeft from "../images/left.png";
import backRight from "../images/right.png";
import backBottom from "../images/logo.png";
import clicktojoin from "../images/joinlowd.jpg";

const isBrowser = typeof window !== "undefined";

export default ({ props }) => {
  useEffect(() => {
    import("@zoomus/websdk").then((module) => {
      const { ZoomMtg } = module;

      ZoomMtg.setZoomJSLib("https://source.zoom.us/2.10.1/lib", "/av");
      console.log("checkSystemRequirements");
      console.log(JSON.stringify(ZoomMtg.checkSystemRequirements()));
      ZoomMtg.preLoadWasm();
      ZoomMtg.prepareJssdk();
      ZoomMtg.i18n.load("en-US");
      ZoomMtg.i18n.reload("en-US");
    });
  });

  // data name
  let randomNumber = 0;
  function getRandomIntInclusive(min, max) {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min + 1) + min); //The maximum is inclusive and the minimum is inclusive
  }
  randomNumber =
    "ธรรมล้านดวง " + getRandomIntInclusive(100000, 999999).toString();

  const [value, setValue] = useState(randomNumber);
  const handleChange = (e) => setValue(e.target.value);

  //data request
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingUrl, setIsLoadingUrl] = useState(false);
  const [data, setData] = useState({});
  const [urlData, setUrlData] = useState({});

  const requestTargetRoom = async () => {
    setIsLoading(!isLoading);
    let apis = [
      "https://api.thedhamma.net/redirect/room/new",
      "https://api2.thedhamma.net/redirect/room/new",
      "https://main-api.dhammajak.live/redirect/room/new",
      "https://main-02-api.dhammajak.live/redirect/room/new",
    ];
    let api = apis[Math.floor(Math.random() * apis.length)];

    const result = await axios({
      method: "get",
      //url: "https://main-api.dhammajak.live/redirect/room/new",
      url: "https://api.thedhamma.net/redirect/room/new",
      // url: "https://api.thedhamma.net/redirect/room",
    });
    console.log(result);

    if (result.status === 200) {
     
      setData(result.data);
      getSignature(result.data.roomID, result.data.passcode);
     
    }

    setIsLoading(!isLoading);
  };


  const getSignature = (roomID, passcode) => {
    axios
      .post("https://gen-sig-sdk-t5wbthbpjq-as.a.run.app", {
        meetingNumber: roomID,
        role: 0,
      })
      .then((res) => {
        console.log(res.data.signature);
        startMeeting(roomID, passcode, res.data.signature);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  function startMeeting(roomID, passcode, signature) {
   
      document.getElementById("zmmtg-root").style.display = "block";
      ZoomMtg.init({
        isSupportAV: true,
        disablePreview: true,
        leaveUrl: "https://zoom.thedhamma.net",
        success: (success) => {
          console.log(success);
          ZoomMtg.join({
            meetingNumber: roomID,
            userName: value,
            signature: signature,
            sdkKey: "OXZLeRjJxOnbDnOBIRQB4VQRtxgXg6VZp3wb",
            passWord: passcode,
            success: (success) => {
              console.log(success);
              setIsLoading(!isLoading);
            },
            error: (error) => {
              console.log(error);
            },
          });
        },
        error: (error) => {
          console.log(error);
        },
      });
     
    
  }

  
  const urlTargetRoom = async () => {
    setIsLoadingUrl(true);
    let apis = [
      "https://api.thedhamma.net/redirect/room/new",
      "https://api2.thedhamma.net/redirect/room/new",
      "https://main-api.dhammajak.live/redirect/room/new",
      "https://main-02-api.dhammajak.live/redirect/room/new",
    ];
    let api = apis[Math.floor(Math.random() * apis.length)];
    const result = await axios({
      method: "get",
      //url: "https://main-api.dhammajak.live/redirect/room/new",
      url: "https://api.thedhamma.net/redirect/room/new",
      // url: "https://api.thedhamma.net/redirect/room",
      // url: "https://random-room-vov26botrq-as.a.run.app/range",
    });

    //console.log(result);
    if (result.status == 200) {
      setUrlData(result.data);

      window.location.href = result.data.base_url;
      //window.location.href = room.base_url;
    }

    setIsLoadingUrl(false);
  };

  // render front page
  return (
    <>
      <Helmet>
        <title>Zoom on web by ธรรมล้านดวง</title>

        <meta name="format-detection" content="telephone=yes"></meta>
        <meta name="title" content="Zoom on Web by ธรรมล้านดวง"></meta>
        <meta
          name="description"
          content="เพจธรรมล้านดวง ร่วมสวดธรรมจักรทุกวัน 2 ทุ่ม"
        ></meta>
        <meta property="og:type" content="website"></meta>
        <meta property="og:url" content="https://zoom.thedhamma.net"></meta>
        <meta property="og:title" content="Zoom on Web by ธรรมล้านดวง"></meta>
        <meta
          property="og:description"
          content="เพจธรรมล้านดวง ร่วมสวดธรรมจักรทุกวัน 2 ทุ่ม"
        ></meta>
        <meta
          property="og:image"
          content="https://logo-file-dhmom.s3.ap-southeast-1.amazonaws.com/tbLogo_resize.png"
        ></meta>
        <link
          type="text/css"
          rel="stylesheet"
          href="https://source.zoom.us/1.9.6/css/bootstrap.css"
        />
        <link
          type="text/css"
          rel="stylesheet"
          href="https://source.zoom.us/1.9.6/css/react-select.css"
        />
      </Helmet>

      <div
        style={{
          display: "flex",
          height: "100%",
          justifyContent: "flex-start",
          flexDirection: "column",
          alignItems: "center",
          position: "relative",
        }}
      >
        {/* Top */}
        <div
          className="colorbg"
          style={{
            height: "15%",
            width: "100%",
            padding: 0,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            position: "relative",
          }}
        >
          <div
            className="bgimgTopLeft"
            style={{
              height: "60%",
              width: "40%",
              backgroundImage: `url(${backLeft})`,
            }}
          ></div>
          <h1
            style={{
              color: "#5c2605",
              textAlign: "center",
              fontFamily: "duangkaewregular",
            }}
          >
            เพจธรรมล้านดวง
          </h1>
          <div
            className="bgimgTopRight"
            style={{
              height: "100%",
              width: "50%",
              backgroundImage: `url(${backRight})`,
            }}
          ></div>
        </div>

        <div
          className=""
          style={{
            height: "30%",
            width: "100%",
            padding: 0,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            position: "relative",
            backgroundImage: `url(${clicktojoin})`,
            backgroundSize: "cover",
          }}
        ></div>

        {/* Bottom */}
        <div
          className="bgimgBottom"
          style={{
            backgroundColor: "#ffffff",
            height: "55%",
            width: "100%",
            position: "absolute",
            bottom: 0,
            backgroundImage: `url(${backBottom})`,
          }}
        >
          {/* part1 */}
          <div style={{ width: "100%", backgroundColor: "#d79f00" }}>
            <div
              style={{
                width: "100%",
                height: 50,
                backgroundColor: "#ffffff",
                borderRadius: "30px 30px 0 0",
                boxShadow: "0 -7px 7px -5px #5B5B5B",
              }}
            ></div>
          </div>

          {/* part2 */}
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              position: "relative",
            }}
          >
            <h3 style={{ marginTop: 0, marginBottom: 0, color: "#e4bb4f" }}>
              ร่วมสวดธรรมจักรทุกวัน 2 ทุ่ม
            </h3>
            <h4
              style={{
                color: "#96938c",
                marginTop: "5px",
                marginBottom: "10px",
              }}
            >
              กรอกชื่อ
            </h4>

            <Textfield
              placeholder="กรอกชื่อของคุณ
      "
              name="basic"
              defaultValue={value}
              onChange={handleChange}
              aria-label="default text field"
              css={{ width: "60%", marginBottom: "30px", textAlign: "center" }}
            />

            <Button
              appearance="primary"
              style={{
                backgroundColor: "#d79f00",
                borderRadius: "10px",
                height: "40px",
                width: "300px",
                boxShadow: "2px 5px 16px 0px #A3A3A3",
                marginBottom: "20px",
                padding: "5px",
              }}
              onClick={requestTargetRoom}
            >
              {isLoading && <Spinner />}
              &nbsp;&nbsp; กดตรงนี้ เข้าร่วมงาน (แอนดรอยด์)
            </Button>

            <Button
              appearance="primary"
              style={{
                backgroundColor: "#2d8cff",
                borderRadius: "10px",
                width: "300px",
                height: "90px",
                boxShadow: "2px 5px 16px 0px #A3A3A3",
                marginBottom: "30px",
                padding: "2px",
                whiteSpace: "normal",
              }}
              onClick={urlTargetRoom}
            >
              {isLoading && <Spinner />}
              &nbsp;&nbsp; กดตรงนี้ เข้าร่วมงาน <br />
              สำหรับผู้มีแอปพลิเคชั่น zoom แล้ว
            </Button>

            {/* <button onClick={urlTargetRoom} style={{ color: "#dea10a" }}>
              {isLoadingUrl && <Spinner />}
              &nbsp;&nbsp;ร่วมกิจกรรมผ่าน Application Zoom
            </button> */}
          </div>
        </div>
      </div>
    </>
  );
};
